import { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ReleaseNotes from '@rio-cloud/rio-uikit/ReleaseNotes';

import forOwn from 'lodash/forOwn';
import { FormattedMessage } from 'react-intl';

import releaseNotes from './releaseNotes';

const getReleases = toggleGlossaryDialog => {
    const releases = [];

    forOwn(
        releaseNotes,
        (note, version, index) => {
            const notePoints =
            note.content.map((point, i) => <li key={`${version}-${index}-note-${i}`}>
                { point(toggleGlossaryDialog) }
            </li>);

            releases.push(<div className={'form-group'} key={`${version}-${index}-note`}>
                <ReleaseNotes
                    releaseNotes={{
                        [version]: {
                            content: <ul className={'padding-left-10'}> {notePoints} </ul>,
                        },
                    }}
                />
            </div>);
        },
    );

    return releases;
};

const max = getReleases().length - 1;
class ReleaseNotesDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentNote: 0,
        };

        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);

    }

    handlePrev() {
        const nextIndex = this.state.currentNote - 1;
        this.setState({currentNote: nextIndex <= 0 ? 0 : nextIndex});
    }

    handleNext() {
        const nextIndex = this.state.currentNote + 1;
        this.setState({currentNote: nextIndex >= max ? max : nextIndex});
    }

    render() {
        const header = <FormattedMessage id={'Compliant'} defaultMessage={'Compliant'}/>;
        const body = (
            <div>
                <div className={'display-flex justify-content-between align-items-start'}>
                    <div className={'text-size-h2 text-medium margin-bottom-15'}>
                        <FormattedMessage id={'whatsNew'} defaultMessage={'What`s new?'}/>
                    </div>
                    <div className={'display-flex justify-content-end margin-bottom-10'}>
                        <button
                            className={`btn btn-muted btn-icon-only ${this.state.currentNote === 0 ? 'disabled' : ''}`}
                            type={'button'}
                            onClick={this.handlePrev}
                        >
                            <span className={'rioglyph rioglyph-chevron-left'} />
                        </button>
                        <button
                            className={`btn btn-muted btn-icon-only 
                                ${this.state.currentNote === max ? 'disabled' : ''}`}
                            type={'button'}
                            onClick={this.handleNext}
                        >
                            <span className={'rioglyph rioglyph-chevron-right'} />
                        </button>
                    </div>
                </div>
                { getReleases(this.props.toggleGlossaryDialog)[this.state.currentNote] }
            </div>
        );

        const footer =
            (<button
                data-component={'CloseButton'}
                type={'button'}
                className={'btn btn-default'}
                onClick={this.props.closeReleaseNotes}
            >
                <FormattedMessage id={'close'} defaultMessage={'Close'}/>
            </button>);

        return (<Dialog
            useOverflow={true}
            show={this.props.showReleaseNotes}
            title={header}
            body={body}
            footer={footer}
            onHide={this.props.closeReleaseNotes}
        />);
    }
}

ReleaseNotesDialog.propTypes = {
    showReleaseNotes: PropTypes.bool,
    toggleGlossaryDialog: PropTypes.func,
    closeReleaseNotes: PropTypes.func,
};

export default ReleaseNotesDialog;
